import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import { GameBackground, YardAndDisplayScreen } from './components/YardAndDisplayScreen';
import { YardScreen } from './YardScreen';
import { identify, initAnalytics, track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { AppBody } from './AppBody';
import { SubmittableInput } from './components/submittable-input/SubmittableInput';
import { Loading } from './Loading';
import { Game, getGameFromId } from '@magicyard/platform-shared/games';
import { MicContext, MicHeader, MicProvider } from './MicHeader';
import { GameButton } from './components/game-button/GameButton';
import { CONFIG } from '@magicyard/utils';
import {
  CombinedYard,
  Profile,
  sendNavigationCommand,
  sendReaction,
  VoiceChatState,
} from '@magicyard/shared/platform/lib/api';
import {
  BaseController,
  Communication,
  StateConfig,
  YardController,
} from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { usePlatformController } from '@magicyard/shared/platform/hooks/usePlatformController';
import { useAskBeforeLeaving } from '@magicyard/shared/src/UseAskBeforeLeaving';
import { ReconnectionMessage } from '@magicyard/platform-shared/src/ReconnectionMessage';
import { BurgerMenu } from './BurgerMenu';
import { YardConnectionStatus } from './YardConnectionStatus';

const MIC_HEADER_KEY = 'mic-header';

export const DEFAULT_NAME = 'Choosing name...';
export const DEFAULT_IMG = `https://api.dicebear.com/6.x/pixel-art/svg?seed=${Math.random()}`;
export const App: () => JSX.Element = () => {
  useAskBeforeLeaving();
  const query = new URLSearchParams(window.location.search);
  useEffect(() => {
    initAnalytics(CONFIG.MIXPANEL_API_KEY, 'Controller', 'Platform', undefined);
    track(`Magicyard Platform Launched`);
  }, []);

  const { toRender, leaveGame, yard, isReconnecting, profile, controller } = usePlatformController<{
    toRender: JSX.Element;
    title?: string;
    isGame?: boolean;
    isReconnecting?: boolean;
    profile?: {
      profile: Profile;
      onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => Promise<void>;
    };
    leaveGame?: () => void;
    controller?: BaseController;
    yard?: CombinedYard;
  }>(
    {
      onGameLoading({ controller, communication, isReconnecting, onProfileUpdate }) {
        identify(controller.profile.id);
        return {
          toRender: <Loading />,
          isReconnecting: isReconnecting,
          profile: { profile: controller.profile, onProfileUpdate },
          controller,
          yard: controller.yard,
        };
      },
      onInitialLoading() {
        return {
          toRender: <Loading toTrack={'Initial Loading'} />,
        };
      },
      onGame(params) {
        return onGame(params);
      },
      onInvalidYard({ controller, onDisplayCodeEntered, onDisplayScanned, isReconnecting, onProfileUpdate }) {
        identify(controller.profile.id);
        return {
          toRender: <InvalidYard onCodeEntered={onDisplayCodeEntered} />,
          title: '',
          isReconnecting: isReconnecting,
          profile: { profile: controller.profile, onProfileUpdate: onProfileUpdate },
          controller,
        };
      },
      onYard({ controller, onDisplayScanned, onRoomCodeEntered, onProfileUpdate, communication, isReconnecting }) {
        identify(controller.profile.id);
        return {
          toRender: (
            <AppBody title={''}>
              <MicHeader comms={communication} controller={controller} key={MIC_HEADER_KEY} />
              <YardScreen
                comms={communication}
                onScanDisplay={onDisplayScanned}
                controller={controller}
                onCodeEntered={onRoomCodeEntered}
                onProfileUpdate={onProfileUpdate}
              />
            </AppBody>
          ),
          title: 'Scan the display',
          isReconnecting: isReconnecting,
          profile: { profile: controller.profile, onProfileUpdate: onProfileUpdate },
          controller,
          yard: controller.yard,
        };
      },
      onYardWithDisplay({ controller, onProfileUpdate, onSubmitOnline, onSubmitLocal, communication, isReconnecting }) {
        identify(controller.profile.id);
        return {
          toRender: (
            <AppBody>
              {/*<VoiceChatActivatedOverlay*/}
              {/*  comms={communication}*/}
              {/*  voiceChatState={controller.yard.voiceChatState}*/}
              {/*  subtitle={'Someone joined your yard and voice chat was activated!'}*/}
              {/*/>*/}
              <MicHeader comms={communication} controller={controller} key={MIC_HEADER_KEY} />
              <YardAndDisplayScreen
                controller={controller}
                onSubmitOnline={onSubmitOnline}
                onSubmitLocal={() => {
                  onSubmitLocal({ force_stream_games: ['ticket-to-ride', 'ticket-to-ride$$dev'] });
                }}
                onProfileUpdate={onProfileUpdate}
                sendNavigationCommand={communication.sendNavigationCommand}
              />
            </AppBody>
          ),
          isReconnecting: isReconnecting,
          profile: { profile: controller.profile, onProfileUpdate: onProfileUpdate },
          controller,
          yard: controller.yard,
        };
      },
      onOnlineQueue({ controller, communication, onLeaveQueue, isReconnecting, onProfileUpdate }) {
        return {
          toRender: <div>TODO</div>,
          isReconnecting: isReconnecting,
          profile: { profile: controller.profile, onProfileUpdate: onProfileUpdate },
          controller,
          yard: controller.yard,
        };
      },
    },
    { displayId: query.get('displayId'), yardId: query.get('yardId') },
    DEFAULT_IMG,
    DEFAULT_NAME
  );

  return (
    <MicProvider>
      <ReconnectionMessage isReconnecting={isReconnecting} />
      {/*{yard !== undefined && (*/}
      {/*  <YardConnectionStatus*/}
      {/*    controllers={yard.controllers}*/}
      {/*    onClick={() => sendNavigationCommand({ command: 'toggleQr' })}*/}
      {/*  />*/}
      {/*)}*/}
      {profile !== undefined && (
        <BurgerMenu
          toggleQr={() => sendNavigationCommand({ command: 'toggleQr' })}
          profile={profile.profile}
          onProfileUpdate={profile.onProfileUpdate}
          leaveGame={leaveGame}
          controller={controller}
        />
      )}
      {toRender}
    </MicProvider>
  );
};

const InvalidYard = ({ onCodeEntered }: { onCodeEntered: (value: string) => void }) => {
  useEffect(() => {
    track('Enter Room Code Loaded');
  }, []);

  const handleCodeEntered = (code: string) => {
    track('Room code entered', { roomCode: code });
    onCodeEntered(code);
  };

  return (
    <AppBody title={'Enter display code'}>
      <div style={{ height: '10vh', margin: '5vh' }}>
        <SubmittableInput
          defaultValue={''}
          type={'number'}
          inputMode={'numeric'}
          forceEnglish={false}
          onSubmit={handleCodeEntered}
        />
      </div>
    </AppBody>
  );
};

const onGame: StateConfig<{
  toRender: JSX.Element;
  title?: string;
  isGame?: boolean;
}>['onGame'] = ({
  controller,
  communication,
  onProfileUpdate,
  onStartAgain,
  onStartAgainOnline,
  onGameEnd,
  isReconnecting,
}) => {
  identify(controller.profile.id);
  updateAnalyticsData({ gameId: controller.yard.gameId });
  const url = new URL(
    (
      controller.gameStartArgs as {
        url: string;
      }
    ).url
  );
  const params = new URLSearchParams(url.search);
  params.append('controllerID', controller.profile.id);
  url.search = params.toString();
  if (params.get('noIframe') !== null) {
    const game = getGameFromId(controller.yard.gameId);
    return { toRender: <GameRequiresNewTab game={game} url={url.toString()} />, isReconnecting: isReconnecting };
  }

  return {
    toRender: (
      <GameWithMessageListener
        onProfileUpdate={onProfileUpdate}
        isReconnecting={isReconnecting}
        url={url.toString()}
        controller={controller}
        onStartAgainOnline={onStartAgainOnline}
        onStartAgain={onStartAgain}
        onGameEnd={onGameEnd}
        communication={communication}
      />
    ),
    isReconnecting: isReconnecting,
    isGame: true,
    profile: {
      profile: controller.profile,
      onProfileUpdate: onProfileUpdate,
    },
    leaveGame: onGameEnd,
    controller,
    yard: controller.yard,
  };
};

const GameWithMessageListener: (
  props: Parameters<StateConfig<JSX.Element>['onGame']>[0] & {
    url: string;
  }
) => JSX.Element = ({
  communication,
  url,
  onStartAgain,
  onStartAgainOnline,
  onGameEnd,
  controller,
  isReconnecting,
}) => {
  useEffect(() => {
    track('Game Started');
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const listener = (ev: MessageEvent<string>) => {
      if (ev.data === 'windowPostMessagePlayAgain') {
        onStartAgain({ isPlayAgain: true, force_stream_games: ['ticket-to-ride', 'ticket-to-ride$$dev'] });
      } else if (ev.data === 'windowPostMessageTryDifferentGames') {
        onGameEnd();
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [onStartAgain, controller.yard.gameId, onGameEnd]);

  return (
    <>
      <MicHeader comms={communication} controller={controller} key={MIC_HEADER_KEY} />
      {isLoading && <Loading />}
      <iframe
        onLoad={() => setIsLoading(false)}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          backgroundColor: '#ff005d',
        }}
        src={url.toString()}
        title={'game'}
        allow={'screen-wake-lock *; fullscreen *; autoplay *; web-share *; gamepad *; '}
      />
    </>
  );
};
const GameRequiresNewTab = ({ url, game }: { url: string; game: Game }) => {
  useEffect(() => {
    track('Game Started');
  }, []);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        background: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          top: '3%',
          position: 'absolute',
          zIndex: 1,
          color: 'white',
          fontSize: '4vh',
        }}
      >
        {game.name}
      </div>
      <GameBackground game={game} />
      <div
        style={{
          color: 'white',
          position: 'absolute',
          textAlign: 'center',
          padding: '20px',
          fontSize: '24px',
        }}
      >
        This game needs to run in a new tab
      </div>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: 'white',
          position: 'absolute',
          textAlign: 'center',
          background: '#ff005d',
          padding: '20px',
          borderRadius: '10px',
          bottom: '20%',
          width: '60%',
          fontSize: '24px',
          textDecoration: 'none',
        }}
      >
        Let's go!
      </a>
    </div>
  );
};

export const ReactionPart = () => {
  return (
    <div className={'app-reaction_root'}>
      <div className={'app-reaction'} onClick={() => sendReaction('🔥')}>
        🔥
      </div>
      <div className={'app-reaction'} onClick={() => sendReaction('💩')}>
        💩
      </div>
      <div className={'app-reaction'} onClick={() => sendReaction('🎮')}>
        🎮
      </div>
    </div>
  );
};

export const VoiceChatActivatedOverlay = ({
  comms,
  voiceChatState,
  subtitle,
}: {
  comms: Communication;
  voiceChatState: VoiceChatState | null;
  subtitle: string;
}) => {
  const { localMicState, setLocalMicState } = useContext(MicContext);
  const isVoiceActive = voiceChatState !== null;
  const [show, setShow] = useState(false);
  const didShow = React.useRef(false);

  useEffect(() => {
    if (isVoiceActive && localMicState === null && !didShow.current) {
      setShow(true);
      didShow.current = true;
    } else {
      setShow(false);
    }
  }, [isVoiceActive, localMicState]);

  if (!show) {
    return null;
  }

  return (
    <Popup
      title={'Voice chat activated'}
      subTitle={subtitle}
      body={<div className={'app-voice_chat_icon'} />}
      footer={
        <>
          <GameButton onClick={() => setShow(false)} variant={'secondary'}>
            Maybe later..
          </GameButton>
          <GameButton
            onClick={async () => {
              setLocalMicState('loading');
              const res = (await comms.voice?.micState?.join()) ?? null;
              setLocalMicState(res);
            }}
          >
            {localMicState === 'loading' ? 'Loading..' : 'Join now!'}
          </GameButton>
        </>
      }
    ></Popup>
  );
};

export const Popup = ({
  title,
  subTitle,
  body,
  footer,
}: {
  title: string;
  subTitle?: string;
  body?: React.ReactNode;
  footer: React.ReactNode;
}) => {
  return (
    <div className={'app-voice_chat_overlay_root'}>
      <div className={'app-voice_chat_overlay_title'}>
        {title}
        {subTitle && <div className={'app-voice_chat_overlay_subtitle'}>{subTitle}</div>}
      </div>
      {body}
      <div className={'app-voice_chat_overlay_btn'}>{footer}</div>
    </div>
  );
};
