import React from 'react';
import './App.css';

export const AppBody = ({ title, children }: { title?: string; children: React.ReactNode | undefined }) => {
  return (
    <>
      {/*<OrientationMessage />*/}
      {/*<ToggleFullScreenButton />*/}
      <div className={'app-body-container'}>
        {title !== undefined && <div className={'app-title'}>{title}</div>}
        {children}
      </div>
    </>
  );
};
