import { useEffectOnce } from 'react-use';
import Lottie from 'lottie-react';
import loading from './assets/loading.json';
import { track } from '@magicyard/shared/src/analytics';

export const Loading = ({ toTrack }: { toTrack?: string }) => {
  useEffectOnce(() => {
    if (toTrack !== undefined) {
      track(toTrack);
    }
  });

  return (
    <Lottie animationData={loading} style={{ width: '100%', height: '100%', backgroundColor: '#FF005D', zIndex: 1 }} />
  );
};
