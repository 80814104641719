import { useEffect } from 'react';

export const useAskBeforeLeaving = () => {
  useEffect(() => {
    const onBeforeUnload: OnBeforeUnloadEventHandler = (e) => {
      var confirmationMessage = 'Are you sure you want to leave?';
      ((e ?? window.event ?? {}) as any).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });
};
