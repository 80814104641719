import { Communication } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import styles from './DPad.module.css';
import { NavigationCommand } from '@magicyard/shared/platform/lib/api';
import { track } from '@magicyard/shared/src/analytics';

export const DPad = ({ sendNavigationCommand }: { sendNavigationCommand: Communication['sendNavigationCommand'] }) => {
  const handleNavigation = (cmd: NavigationCommand) => {
    track('Dpad Interaction', { cmd: cmd.command });

    sendNavigationCommand(cmd);
  };
  return (
    <div className={styles.dPad}>
      {/*<div className={`${styles.dPadArrow} ${styles.arrowBack}`}*/}
      {/*     onClick={() => handleNavigation({ command: 'back' })}>*/}
      {/*  <div className={styles.dPadRotateIcon}/>*/}
      {/*</div>*/}
      <div className={`${styles.dPadArrow} ${styles.arrowUp}`} onClick={() => handleNavigation({ command: 'up' })}>
        <div className={styles.dPadArrowIcon} style={{ transform: 'rotate(-90deg)' }} />
      </div>
      <div className={styles.leftRight}>
        <div
          className={`${styles.dPadArrow} ${styles.arrowLeft}`}
          onClick={() => handleNavigation({ command: 'left' })}
        >
          <div className={styles.dPadArrowIcon} style={{ transform: 'rotate(180deg)' }} />
        </div>
        <div className={styles.dPadArrow} onClick={() => handleNavigation({ command: 'enter' })}>
          <div className={styles.dPadEnterIcon} />
        </div>
        <div
          className={`${styles.dPadArrow} ${styles.arrowRight}`}
          onClick={() => handleNavigation({ command: 'right' })}
        >
          <div className={styles.dPadArrowIcon} />
        </div>
      </div>
      <div className={`${styles.dPadArrow} ${styles.arrowDown}`} onClick={() => handleNavigation({ command: 'down' })}>
        <div className={styles.dPadArrowIcon} style={{ transform: 'rotate(90deg)' }} />
      </div>
    </div>
  );
};
