import React, { ReactChild, useState } from 'react';
import { SubmittableInput } from './components/submittable-input/SubmittableInput';
import { EditNamePopUp } from './components/YardAndDisplayScreen';
import './YardScreen.css';
import { assertNever } from '@magicyard/utils/typeUtils';
import { GameButton } from './components/game-button/GameButton';
import { DEFAULT_NAME, VoiceChatActivatedOverlay } from './App';
import { Communication, YardController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { Profile } from '@magicyard/shared/platform/lib/api';

type Steps = 'landing' | 'choice' | 'pc' | 'tv' | 'input';

export const YardScreen = ({
  comms,
  onScanDisplay,
  controller,
  onCodeEntered,
  onProfileUpdate,
}: {
  comms: Communication;
  onScanDisplay: (url: string) => void;
  onCodeEntered: (code: string) => void;
  controller: YardController;
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
}) => {
  const [step, setStep] = useState<Steps>('landing');
  return (
    <div className={'yard_screen-root'}>
      {step === 'choice' ? (
        <EditNamePopUp
          defaultValue={controller.profile.name === DEFAULT_NAME ? '' : null}
          onSubmit={(v) => {
            onProfileUpdate({ name: v });
          }}
        />
      ) : (
        ''
      )}
      <GetComponentForStep
        step={step}
        setStep={setStep}
        onCodeEntered={onCodeEntered}
        controller={controller}
        comms={comms}
      />
    </div>
  );
};

const GetComponentForStep = ({
  step,
  setStep,
  onCodeEntered,
  controller,
  comms,
}: {
  step: Steps;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
  onCodeEntered: (code: string) => void;
  controller: YardController;
  comms: Communication;
}) => {
  switch (step) {
    case 'landing':
      return (
        <>
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
            <Title>
              Welcome to <span style={{ color: '#ff005d' }}>magicyard</span>!
            </Title>
            <YardPart controller={controller} />
          </div>
          <div style={{ width: '60%', marginBottom: '15%' }}>
            <GameButton
              onClick={async () => {
                setStep('choice');
              }}
            >
              Join them!
            </GameButton>
          </div>
        </>
      );
    case 'choice':
      return (
        <div>
          <VoiceChatActivatedOverlay
            comms={comms}
            voiceChatState={controller.yard.voiceChatState}
            subtitle={'Turn on voice chat to speak with your friends'}
          />
          <Title>Which device will you be using?</Title>
          <div className={'yard-tv_pc_container'} style={{ margin: '6vh 0' }}>
            <GameButton onClick={() => setStep('tv')}>
              TV
              <div className={'yard_screen-tv'} />
            </GameButton>
            <GameButton onClick={() => setStep('pc')}>
              PC/MAC
              <div className={'yard_screen-pc'} />
            </GameButton>
          </div>
        </div>
      );
    case 'pc':
      return (
        <div className={'yard-pc_container'}>
          <Title>
            Go to <span style={{ color: '#ff0071' }}>mgy.gg</span> in your browser
          </Title>
          <div className={'yard_screen-browser'} />
          <div style={{ width: '90%' }}>
            <GameButton onClick={() => setStep('input')}>Done!</GameButton>
          </div>
        </div>
      );
    case 'tv':
      return (
        <div className={'yard-pc_container'}>
          <Title>
            Download the <span style={{ color: '#ff0071' }}>Magicyard</span> app on your TV
          </Title>
          <div className={'yard-tv_download_icon'}></div>
          <div style={{ width: '90%' }}>
            <GameButton onClick={() => setStep('input')}>Done!</GameButton>
          </div>
        </div>
      );
    case 'input':
      return (
        <>
          <Title>Input the pin as shown below!</Title>
          <div className={'yard_screen-pin_example'} />
          <div style={{ height: '10vh', marginTop: '5vh', width: '80vw', display: 'flex', alignItems: 'center' }}>
            <div className={'yard_screen-pin'} />
            <SubmittableInput
              autoFocus={false}
              tabIndex={-1}
              defaultValue={''}
              placeholder={'Enter code..'}
              type={'number'}
              inputMode={'numeric'}
              forceEnglish={false}
              onSubmit={onCodeEntered}
            />
          </div>
        </>
      );
    default:
      assertNever(step);
  }
};

const YardPart = ({ controller }: { controller: YardController }) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: 12, fontSize: 24 }}>{controller.yard.name}</div>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
        {controller.yard.controllers
          .filter((x) => x.profile.id !== controller.profile.id)
          .map((c) => (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={c.profile.id}>
              <div
                style={{
                  backgroundImage: `url(${c.profile.avatarUrl})`,
                  width: '10vh',
                  height: '10vh',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  backgroundSize: 'contain',
                }}
              />
              {c.profile.name}
            </div>
          ))}
      </div>
    </>
  );
};

const Title = ({ children }: { children: ReactChild | ReactChild[] }) => (
  <div className={'yard_screen-title'}>{children}</div>
);
