import React, { FC, useCallback, useRef } from 'react';
import { Dialog, DialogContent, IconButton, Slide } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Webcam from 'react-webcam';
import './TakePicDialog.css';
import { track } from '@magicyard/shared/src/analytics';
import { GameButton } from './game-button/GameButton';
import { AppBody } from '../AppBody';

export interface TakePicProps {
  open: boolean;
  onClose: () => void;
  onTakePic: (data: string | null) => void;
}

const videoConstraints: MediaTrackConstraints = {
  aspectRatio: 1,
  facingMode: 'user',
};

const TakePicDialog: FC<TakePicProps> = ({ open, onTakePic, onClose }) => {
  const webcamRef = useRef<Webcam>(null);
  const [loading, setLoading] = React.useState(false);

  const capture = useCallback(() => {
    if (webcamRef.current === null) {
      console.warn("Webcam ref isn't set");
      return;
    }
    const picData = webcamRef.current.getScreenshot({
      width: 300,
      height: 300,
    });
    if (picData === null) {
      console.warn('picData is null');
      return;
    }
    setLoading(true);
    uploadPic(picData)
      .then((url) => {
        onTakePic(url);
        track('Selfie Taken');
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  }, [setLoading, webcamRef, onTakePic, onClose]);

  const onCaptureClick = useCallback(() => {
    capture();
    track('Selfie taken');
  }, [capture]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Slide}
      TransitionProps={{
        // @ts-expect-error
        direction: 'up',
      }}
    >
      <AppBody>
        <div className={'take-pic-dialog-root'}>
          <IconButton className={'take-pic-dialog-close-icon-button'} onClick={onClose} style={{ color: 'white' }}>
            <Close />
          </IconButton>
          <DialogContent
            className={'take-pic-dialog-image-wrapper'}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {loading ? (
              <div style={{ fontSize: 32 }}>Uploading...</div>
            ) : (
              <>
                <Webcam
                  className={'take-pic-dialog-image'}
                  audio={false}
                  ref={webcamRef}
                  forceScreenshotSourceSize
                  mirrored={true}
                  height="300"
                  width="300"
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
                <div style={{ height: '11vh', width: '100%', marginTop: '3vh' }}>
                  <GameButton onClick={onCaptureClick}>Capture</GameButton>
                </div>
              </>
            )}
          </DialogContent>
        </div>
      </AppBody>
    </Dialog>
  );
};

export default TakePicDialog;

async function uploadPic(picData: string): Promise<string> {
  const resp = await fetch('https://image-upload.magicyard.workers.dev/', {
    method: 'POST',
    body: JSON.stringify({ url: picData, filename: `blanksy/seflies/${Date.now()}` }),
    headers: { 'Content-Type': 'application/json' },
  });
  const uploadResponse = await resp.json();
  return uploadResponse.result.variants[0];
}
