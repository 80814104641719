import React, { HTMLInputTypeAttribute, useEffect } from 'react';
import './SubmittableInput.css';

interface BrushInputProps {
  value?: string;
  defaultValue?: string;
  isValid?: boolean;
  onClick?: () => void;
  onChange?: (value: string) => void;
  onSubmit: (value: string) => void;
  style?: React.CSSProperties;
  forceEnglish: boolean;
  type?: HTMLInputTypeAttribute;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  placeholder?: string;
  tabIndex?: number;
  autoFocus?: boolean;
  multiline?: boolean;
}

export const SubmittableInput = ({
  value,
  defaultValue,
  onClick,
  style,
  onSubmit,
  onChange,
  isValid,
  forceEnglish,
  type = 'text',
  inputMode,
  placeholder,
  tabIndex,
  autoFocus,
  multiline,
}: BrushInputProps) => {
  const [msg, setMsg] = React.useState('');
  const [msgTimeout, setMsgTimeout] = React.useState<number | undefined>(undefined);
  const [text, setText] = React.useState(value ?? defaultValue ?? '');

  useEffect(() => {
    return () => {
      window.clearTimeout(msgTimeout);
    };
  }, [msgTimeout]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newVal = forceEnglish ? event.target.value.replace(/[^a-zA-Z ]/g, '') : event.target.value;
    if (event.target.value.length !== newVal.length) {
      setMsg('Only english letters are allowed');
      window.clearTimeout(msgTimeout);
      setMsgTimeout(
        window.setTimeout(() => {
          setMsg('');
        }, 1500)
      );
    } else {
      setMsg('');
      if (onChange !== undefined) {
        onChange(newVal);
      }
      setText(newVal);
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      onSubmit(text);
    }
  };

  return (
    <span onClick={onClick} className={'submittable-input_root'}>
      {multiline === true ? (
        <textarea
          inputMode={inputMode}
          autoFocus={autoFocus ?? true}
          value={value ?? text}
          onKeyUp={handleKeyPress}
          onChange={handleChange}
          className={'submittable-input_input'}
          placeholder={placeholder}
          tabIndex={tabIndex}
        />
      ) : (
        <input
          inputMode={inputMode}
          autoFocus={autoFocus ?? true}
          type={type}
          value={value ?? text}
          onKeyUp={handleKeyPress}
          onChange={handleChange}
          className={'submittable-input_input'}
          placeholder={placeholder}
          tabIndex={tabIndex}
        />
      )}
      <div className={'submittable-input_submit'} onClick={() => onSubmit(text)}>
        <svg width="50%" height="50%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.0519 14.8285L13.4661 16.2427L17.7088 12L13.4661 7.7574L12.0519 9.17161L13.8804 11H6.34321V13H13.8803L12.0519 14.8285Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7782 19.7782C24.0739 15.4824 24.0739 8.51759 19.7782 4.22183C15.4824 -0.0739417 8.51759 -0.0739417 4.22183 4.22183C-0.0739417 8.51759 -0.0739417 15.4824 4.22183 19.7782C8.51759 24.0739 15.4824 24.0739 19.7782 19.7782ZM18.364 18.364C21.8787 14.8492 21.8787 9.15076 18.364 5.63604C14.8492 2.12132 9.15076 2.12132 5.63604 5.63604C2.12132 9.15076 2.12132 14.8492 5.63604 18.364C9.15076 21.8787 14.8492 21.8787 18.364 18.364Z"
            fill="white"
          />
        </svg>
      </div>
      <div className={'submittable-input_warning'}>{msg}</div>
    </span>
  );
};
