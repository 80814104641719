import React, { useEffect, useState } from 'react';
import './YardAndDisplayScreen.css';
import { GameButton } from './game-button/GameButton';
import { Game, getGameFromId } from '@magicyard/platform-shared/games';
import { DEFAULT_NAME, Popup, ReactionPart } from '../App';
import { SubmittableInput } from './submittable-input/SubmittableInput';
import TakePicDialog from './TakePicDialog';
import cameraIcon from '../assets/cameraIcon.webp';
import editPen from '../assets/editPen.webp';
import { track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { Profile, Yard, YardWithGame } from '@magicyard/shared/platform/lib/api';
import { Communication, YardWithDisplayController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { Dialog, Slide } from '@material-ui/core';
import { AppBody } from '../AppBody';
import { Textfit } from 'react-textfit';
import { DPad } from './DPad';
// import 'share-api-polyfill';
// import { getYardInviteLink } from '@magicyard/utils';

export const EditNamePopUp = ({
  defaultValue,
  onSubmit,
}: {
  defaultValue: string | null;
  onSubmit: (value: string) => void;
}) => {
  return (
    <Dialog
      open={defaultValue !== null}
      fullScreen
      TransitionComponent={Slide}
      TransitionProps={{
        // @ts-expect-error
        direction: 'up',
      }}
    >
      <AppBody>
        <div
          style={{
            height: '10vh',
            width: '80%',
            marginTop: '5vh',
            margin: '5vh',
            textAlign: 'center',
            fontSize: '4vh',
          }}
        >
          Choose a name
          <SubmittableInput
            placeholder={'Enter name!'}
            defaultValue={defaultValue ?? ''}
            forceEnglish={false}
            onSubmit={(v) => {
              if (v.length > 1) {
                onSubmit(v);
              }
            }}
          />
        </div>
      </AppBody>
    </Dialog>
  );
};

export const YardAndDisplayScreen = ({
  controller,
  onSubmitOnline,
  onSubmitLocal,
  onProfileUpdate,
  sendNavigationCommand,
}: {
  controller: YardWithDisplayController;
  onSubmitOnline: () => void;
  onSubmitLocal: (extras?: any) => void;
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
  sendNavigationCommand: Communication['sendNavigationCommand'];
}) => {
  const host = controller.yard.host ?? controller.yard.controllers[controller.yard.controllers.length - 1];
  const isHost = host.profile.id === controller.profile.id;
  useEffect(() => {
    updateAnalyticsData({ yardId: controller.yard.id });
    track('Yard With Display Loaded');
  }, [controller.yard.id]);

  const [isDev, setIsDev] = useState(0);
  const [showBeforeStartPopup, setShowBeforeStartPopup] = useState(false);

  const game = getGame(controller.yard);

  // const handleInviteClick = () => {
  //   void share({
  //     text: 'Lets play together!',
  //     url: getYardInviteLink(controller.yard.id),
  //   });
  // };

  useEffect(() => {
    updateAnalyticsData(game === null ? {} : { gameId: game.baseId });
  }, [game]);

  const doesHaveEnoughPlayersForGame = (game: Game) => {
    return (
      controller.yard.controllers.length >= game.minPlayers && controller.yard.controllers.length <= game.maxPlayers
    );
  };

  const isDisabled = (game === null || !game.isPlayable || !doesHaveEnoughPlayersForGame(game)) && isDev < 4;

  return (
    <>
      {showBeforeStartPopup && (
        <Popup
          title={'Is everyone ready?'}
          subTitle={`Don't leave anyone behind!`}
          body={<div className={'yard-and-display-popup_body'} />}
          footer={
            <>
              <GameButton
                onClick={() => {
                  track('Not everyone ready clicked');
                  setShowBeforeStartPopup(false);
                }}
                variant={'secondary'}
              >
                Not yet...
              </GameButton>
              <GameButton
                onClick={() => {
                  track('Start game clicked');
                  onSubmitLocal();
                }}
              >
                Lets go!
              </GameButton>
            </>
          }
        />
      )}
      {game !== null && <GameBackground game={game} />}
      <div className={`yard-and-display_body ${game === null ? '' : 'yard-and-display_body-selected'}`}>
        <ProfilePart
          onProfileUpdate={onProfileUpdate}
          profile={controller.profile}
          forceRename={true}
          isHost={isHost}
        />
        {!isHost && <ReactionPart />}
      </div>
      {/*<div className={'yard-and-display-screen_invite'}>*/}
      {/*  <GameButton onClick={handleInviteClick}>Invite friends</GameButton>*/}
      {/*</div>*/}
      <div className={'yard-and-display-screen_game'}>
        {!isHost ? (
          `Waiting for ${host.profile.name}👑`
        ) : (
          <div className={'yard-and-display-screen_buttons-container'}>
            {game === null && <DPad sendNavigationCommand={sendNavigationCommand} />}
            {game !== null && (
              <>
                <div className={'yard-and-display-screen_start-button-container'}>
                  <GameButton
                    onClick={
                      isDisabled
                        ? undefined
                        : () => {
                            track('Show "Is everyone here" popup');
                            setShowBeforeStartPopup(true);
                          }
                    }
                    disabled={isDisabled}
                  >
                    {!game.isPlayable
                      ? 'Coming soon!'
                      : !doesHaveEnoughPlayersForGame(game) && isDev < 4
                      ? `${
                          game.minPlayers > controller.yard.controllers.length
                            ? `Missing ${(game.minPlayers ?? 0) - controller.yard.controllers.length} players`
                            : `Too many players..`
                        }`
                      : `Start ${game.name}!`}
                  </GameButton>
                </div>
                <div style={{ width: '70%', marginTop: '10%' }}>
                  <GameButton
                    onClick={() => {
                      track('Dpad Interaction', { cmd: 'back' });
                      sendNavigationCommand({ command: 'back' });
                    }}
                    variant={'secondary'}
                  >
                    Change game
                  </GameButton>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div
        style={{ position: 'absolute', top: 0, left: 0, height: 70, width: '10%' }}
        onClick={() => setIsDev((x) => x + 1)}
      />
    </>
  );
};

export const ProfilePart = ({
  onProfileUpdate,
  profile,
  forceRename,
  isHost,
}: {
  isHost?: boolean;
  onProfileUpdate: any;
  profile: Profile;
  forceRename: boolean;
}) => {
  const [isTakingPic, setIsTakingPic] = useState(false);
  const [isEditingName, setIsEditingName] = useState<string | null>(
    forceRename ? (profile.name === DEFAULT_NAME ? '' : null) : null
  );
  return (
    <>
      <EditNamePopUp
        defaultValue={isEditingName}
        onSubmit={(v) => {
          track('Name entered', { name: v });
          onProfileUpdate({ name: v });
          setIsEditingName(null);
        }}
      />
      <TakePicDialog
        open={isTakingPic}
        onClose={() => setIsTakingPic(false)}
        onTakePic={(x) => onProfileUpdate({ avatarUrl: x ?? undefined })}
      />
      <div className={'yard-and-display-screen_profile-part-root'}>
        <div className={'yard-and-display-screen_avatar-container'}>
          <div
            className={'yard-and-display-screen_avatar'}
            style={{
              backgroundImage: `url(${profile.avatarUrl})`,
            }}
            onClick={() => {
              track('Selfie camera clicked');
              setIsTakingPic(true);
            }}
          >
            <div className={'yard-and-display-screen_camera-container'}>
              <div
                className={'yard-and-display-screen_camera'}
                style={{
                  backgroundImage: `url(${cameraIcon})`,
                }}
              />
            </div>
          </div>

          <div className={'yard-and-display-screen_name'} onClick={() => setIsEditingName(profile.name)}>
            <Textfit
              forceSingleModeWidth={true}
              max={40}
              mode={'single'}
              style={{ width: '80%', marginRight: '10vw', marginLeft: '4vw' }}
            >
              {profile.name}
              {isHost && ' 👑'}
            </Textfit>
            <div
              style={{
                width: '3vh',
                height: '3vh',
                backgroundImage: `url(${editPen})`,
                position: 'absolute',
                backgroundSize: 'contain',
                right: '1vw',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const GameBackground = ({ game }: { game: Game }) => {
  return (
    <div
      key={game.id}
      className={'yard-and-display-screen_bg'}
      style={{
        backgroundImage: `linear-gradient(0, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%), url(${game.bg})`,
      }}
    />
  );
};

function getGame(yard: YardWithGame | Yard): Game | null {
  if (yard.type !== 'withGame') {
    return null;
  }
  return getGameFromId(yard.gameId);
}

// async function share(data: ShareData): Promise<void> {
//   (window.navigator as any).share(data, SHARE_POLYFILL_OPTIONS).catch((error: any) => {
//     console.error(error);
//   });
// }

// const SHARE_POLYFILL_OPTIONS = {
//   copy: true,
//   email: true,
//   print: true,
//   sms: true,
//   messenger: false,
//   facebook: false,
//   whatsapp: true,
//   twitter: true,
//   linkedin: false,
//   telegram: true,
//   skype: false,
//   pinterest: false,
// };
